@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom utility classes */
@layer utilities {
  .aspect-square {
    aspect-ratio: 1 / 1;
  }

  .aspect-16-10 {
    aspect-ratio: 16 / 10;
  }
}

/* Custom components */
@layer components {
  .input-label {
    @apply block text-sm font-medium mb-1;
  }

  .input-hint {
    @apply block text-gray-500 text-xs mt-1;
  }

  .form-input {
    @apply w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500;
  }

  .task-item {
    @apply p-3 rounded-lg border cursor-pointer transition-all;
  }

  .task-item-complete {
    @apply bg-green-50 border-green-200;
  }

  .task-item-incomplete {
    @apply bg-white hover:bg-gray-50;
  }
}

/* Animation utilities */
@layer utilities {
  .transition-smooth {
    @apply transition-all duration-200 ease-in-out;
  }
}

/* Scrollbar styling */
.scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: #CBD5E0 #EDF2F7;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #EDF2F7;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 3px;
}

/* Print styles */
@media print {
  .no-print {
    display: none;
  }
}

@keyframes slide-in-bottom {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-in-bottom {
  animation: slide-in-bottom 0.5s ease-out forwards;
}